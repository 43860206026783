import styles from "./Tool.module.css";

export default function Tool() {
  return (
    <iframe
      className={styles.tweets}
      src={process.env.PUBLIC_URL + "/twitter.html"}
      width="auto"
      title="Recent Foojis"
    ></iframe>
  );
}
