import { useParams } from "react-router-dom";

import useDeal from "../hooks/useDeal";

import DealView from "../components/DealView";

export default function DealPage() {
  const { dealId } = useParams();
  const [value, loading, error] = useDeal(dealId);

  return (
    <div>
      {loading ? (
        "Loading..."
      ) : error ? (
        "Error!"
      ) : (
        <DealView dealInfo={value} />
      )}
    </div>
  );
}
