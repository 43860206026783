import { createContext, Dispatch } from "react";

interface ViewContextInterface {
  isSmallScreen: boolean;
  isSidebarVisible: boolean;
  setSidebarVisibility: Dispatch<boolean>;
}

export const ViewContext = createContext<ViewContextInterface>(
  {} as ViewContextInterface
);
