import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function DealRedirect() {
  const { dealId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/d/${dealId}`);
  }, [navigate, dealId]);

  return (
    <div className="paddedContainer">
      <h1>Redirecting...</h1>
    </div>
  );
}
