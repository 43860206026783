import InnerHTML from "dangerously-set-html-content";
import { useEffect, useRef } from "react";

interface NativeShoppingAdProps {
  advertisementId: string;
}

export default function NativeShoppingAd({
  advertisementId,
}: NativeShoppingAdProps) {
  const adRef = useRef<HTMLDivElement>(null);

  // cleanup div ID change
  useEffect(() => {
    if (adRef.current?.innerHTML) adRef.current.innerHTML = "";
  }, [advertisementId]);

  return (
    <>
      <div id={`amzn-assoc-ad-${advertisementId}`} ref={adRef}></div>
      <InnerHTML
        html={`<script async src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=${advertisementId}"></script>`}
      />
    </>
  );
}
