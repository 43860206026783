import { createContext } from "react";
import firebase from "firebase/app";

interface FirebaseContextInterface {
  firebaseApp: firebase.app.App;
}

export const FirebaseContext = createContext<FirebaseContextInterface>(
  {} as FirebaseContextInterface
);
