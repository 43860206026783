import { useContext, useEffect, useState } from "react";

import Deal from "../types/Deal";

import { FirebaseContext } from "../context/FirebaseContext";

type DealHookResponse = [Deal, boolean, Error | null];

export default function useDeal(dealId: string): DealHookResponse {
  const { firebaseApp } = useContext(FirebaseContext);

  const [value, setValue] = useState<Deal>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    firebaseApp
      .firestore()
      .doc(`deals/${dealId}`)
      .get()
      .then((val) => {
        setValue(val.data() as Deal); // set value
        setLoading(false); // no longer loading
      })
      .catch(setError);
  }, [firebaseApp, dealId]);

  return [value, loading, error];
}
