import { memo, useMemo } from "react";

// components
import NativeShoppingAd from "../components/amazon/NativeShoppingAd";
// import KoFiButton from "../components/KoFiButton";
import CopyToClipboardButton from "../components/CopyToClipboardButton";

// styles
import styles from "./Donate.module.css";

function Donate() {
  // Ad-Block disable recommendation that shows when
  // the user has ads blocked
  const adBlockRecommendation = useMemo(
    () =>
      (window as any).ADBLOCK_ENABLED ? (
        <li>
          Consider{" "}
          <a
            href="https://www.google.com/search?q=how+to+disable+adblock"
            target="_blank"
            rel="noopener noreferrer"
          >
            disabling your ad-blocker
          </a>{" "}
          to help us keep the lights on.
        </li>
      ) : null,
    []
  );

  return (
    <div className="paddedContainer">
      <h1 className={styles.donateHeading}>Support Us</h1>
      <p>
        While we provide this service free of charge, we must invest time and
        money to keep this service running for everyone. We appreciate donations
        of any size, but if you can't afford to donate, that is completely fine.
      </p>
      <h2>Donate</h2>
      <ul>
        <li style={{ marginBottom: 10 }}>
          Donate with Cash App:{" "}
          <a
            href="https://cash.app/$ZanexFA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>$ZanexFA</strong>
          </a>
        </li>
        <DonateCryptoItem
          name="Monero"
          symbol="XMR"
          website="https://www.getmonero.org/"
          address="49xmPUN4ey227eWdSM6K389uHY9h7q7Nx1S7QvoKVUyCX49c4fPg97d9fkvSbkNMs7ZB7pSY36QuMFmDhNStWQA9PdtsSUJ"
        />
        <DonateCryptoItem
          name="Nano"
          symbol="NANO"
          website="https://nano.org/"
          address="nano_3q69qqsh3jo9n7s3je9pboema1ip4fm3impsjtqch9e5f9wwmm4jgecm3epf"
        />
        <DonateCryptoItem
          name="Banano"
          symbol="BAN"
          website="https://banano.cc/"
          address="ban_317669i3bewwfo1qngr9d7up3o75yjpfgt38d6usaee5j9ifuea1bo8qhg94"
        />
      </ul>
      <h2>If you can't donate</h2>
      <p>
        If you don't have money to spare, here are some ways you can support us:
      </p>
      <ul>
        {adBlockRecommendation}
        <li>Share FreebAlert with your friends to help our community grow!</li>
      </ul>
      <NativeShoppingAd advertisementId="cb5dc13e-3d79-4901-ab34-8c95a68552a9" />
    </div>
  );
}

interface DonateCryptoItemProps {
  name: string;
  symbol: string;
  website: string;
  address: string;
}
function DonateCryptoItem({
  name,
  symbol,
  website,
  address,
}: DonateCryptoItemProps) {
  return (
    <li className={styles.donateCryptoItem}>
      Donate{" "}
      <a href={website} target="_blank" rel="noopener noreferrer">
        {name}
      </a>{" "}
      ({symbol}):{" "}
      <CopyToClipboardButton
        text={address}
        defaultContent="copy address"
        copiedContent="copied!"
      />
    </li>
  );
}

export default memo(Donate);
