import { useMemo } from "react";
import TweetEmbed from "react-tweet-embed";
import NativeShoppingAd from "../amazon/NativeShoppingAd";

import Deal from "../../types/Deal";

// styles
import styles from "./DealView.module.css";

interface DealInfoProps {
  dealInfo: Deal;
}

export default function DealView({ dealInfo }: DealInfoProps) {
  const clickToEnterButton = useMemo(
    () =>
      dealInfo?.hashtags ? (
        <a
          href={generateAutoTweetUrl(
            dealInfo.hashtags.map((tag) => "#" + tag).join(" ") +
              " @" +
              dealInfo.user
          )}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.tweetButton}
          role="button"
        >
          Click to Tweet
        </a>
      ) : null,
    [dealInfo]
  );

  return dealInfo ? (
    <div className="paddedContainer">
      {clickToEnterButton}
      <TweetEmbed id={dealInfo.postId} />
      <NativeShoppingAd advertisementId="cb5dc13e-3d79-4901-ab34-8c95a68552a9" />
    </div>
  ) : (
    <div>Not Found</div>
  );
}

const generateAutoTweetUrl = (statusBody: string) =>
  `http://twitter.com/intent/tweet?text=${encodeURIComponent(statusBody)}`;
