import { ReactNode, useCallback, useContext } from "react";
import { ViewContext } from "../../context/ViewContext";
import styles from "./OuterContainer.module.css";

interface OuterContainerProps {
  children: ReactNode;
}

export default function OuterContainer({ children }: OuterContainerProps) {
  const { isSidebarVisible, setSidebarVisibility } = useContext(ViewContext);

  const handleClick = useCallback(
    function handleClick() {
      requestAnimationFrame(() => {
        if (isSidebarVisible && setSidebarVisibility) {
          // if sidebar is visible
          setSidebarVisibility(false);
        }
      });
    },
    [isSidebarVisible, setSidebarVisibility]
  );

  return (
    <div
      className={
        styles.outerContainer + (isSidebarVisible ? " " + styles.obscured : "")
      }
      onClick={handleClick}
    >
      {children}
    </div>
  );
}
