export interface NavItemInterface {
  name: string;
  path: string;
}

export const navItems = [
  {
    name: "Home",
    path: "/",
  },
  // {
  //   name: "Recent",
  //   path: "/recent",
  // },
  {
    name: "Support Us",
    path: "/donate",
  },
];
