import { useEffect, useMemo, useState } from "react";
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import ReactGA from "react-ga";

// firebase
import { FirebaseContext } from "./context/FirebaseContext";
import firebaseApp from "./util/firebase";

// misc. context
import { ViewContext } from "./context/ViewContext";

// hooks
import useWindowDimensions from "./hooks/useWindowDimensions";

// components
import OuterContainer from "./components/OuterContainer";
import SideBar from "./components/SideBar";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

// pages (todo: React.lazy + Suspense)
import Home from "./pages/Home";
import Tool from "./pages/Tool";
import ToolRedirect from "./pages/ToolRedirect";
import Donate from "./pages/Donate";
import DealPage from "./pages/DealPage";
import DealRedirect from "./pages/DealRedirect";

// styles
import styles from "./App.module.css";

export default function App() {
  const { pathname, search } = useLocation();

  const analytics = useMemo(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize(
        process.env.REACT_APP_GOOGLE_ANALYTICS_UA_TAG as string
      );
      return ReactGA;
    }
  }, []);

  // pageview logging
  useEffect(() => {
    analytics?.pageview(pathname + search);
  }, [pathname, search, analytics]);

  // scroll restoration on pathname change
  useEffect(() => {
    window.scrollTo(0, 0); // reset scroll position
  }, [pathname]);

  return (
    <FirebaseContext.Provider value={{ firebaseApp }}>
      <Routes>
        <Route element={<AppLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="recent" element={<Tool />} />
          <Route path="tool" element={<ToolRedirect />} />
          <Route path="donate" element={<Donate />} />
          <Route path="d/:dealId" element={<DealPage />} />
          <Route path="deal/:dealId" element={<DealRedirect />} />
        </Route>
      </Routes>
    </FirebaseContext.Provider>
  );
}

function AppLayout() {
  const [isSidebarVisible, setSidebarVisibility] = useState(false);

  const { width } = useWindowDimensions();

  return (
    <ViewContext.Provider
      value={{
        isSidebarVisible,
        setSidebarVisibility,
        isSmallScreen: width <= 825,
      }}
    >
      <SideBar />
      <OuterContainer>
        <NavBar />
        <div className={styles.appContainer}>
          <Outlet />
        </div>
        <Footer />
      </OuterContainer>
    </ViewContext.Provider>
  );
}
