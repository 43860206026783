import { Link, useLocation } from "react-router-dom";
import { MdMenu } from "react-icons/md";
// import KoFiButton from "../KoFiButton";
import styles from "./NavBar.module.css";

// nav item list
import { navItems } from "../../config/navItems";
import { useContext } from "react";
import { ViewContext } from "../../context/ViewContext";

export default function NavBar() {
  const { pathname } = useLocation();
  const { isSmallScreen, setSidebarVisibility } = useContext(ViewContext);

  return (
    <nav
      className={
        styles.navBar + (isSmallScreen ? " " + styles.navBarSmall : "")
      }
    >
      <button
        className={
          styles.sideBarButton + (isSmallScreen ? "" : " " + styles.hidden)
        }
        title="Menu"
        onClick={() => setSidebarVisibility(true)}
      >
        <MdMenu className={styles.sideBarIcon} />
      </button>
      <Link to="/" className={styles.logoArea}>
        <img
          src="/site_logo.png"
          className={styles.logoImg}
          alt="App Logo"
        />
        <span className={styles.logoText}>FreebAlert</span>
      </Link>
      <div className={styles.navButtons}>
        {navItems.map((item) => (
          <Link
            className={
              styles.navButton +
              // conditionally adds active style when on that page
              (item.path === pathname ? " " + styles.activeItem : "")
            }
            to={item.path}
            key={item.path}
          >
            {item.name}
          </Link>
        ))}
      </div>
    </nav>
  );
}
