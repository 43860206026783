import { CSSProperties, useCallback, useContext, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import { navItems, NavItemInterface } from "../../config/navItems";
import { ViewContext } from "../../context/ViewContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import styles from "./SideBar.module.css";

export default function SideBar() {
  const { height } = useWindowDimensions(); // window height
  const { isSidebarVisible } = useContext(ViewContext);

  // memoized, yay
  const sideBarItems = useMemo(
    () => navItems.map((item) => <SideBarItem item={item} key={item.path} />),
    []
  );

  // also memoized (for performance)
  const style = useMemo(() => {
    let style: CSSProperties = { height };
    if (isSidebarVisible) {
      style.transform = "translateX(0px)";
    }
    return style;
  }, [height, isSidebarVisible]);

  // controls aria-hidden (accessibility concerns)
  const visibilityProps = useMemo(
    () => (isSidebarVisible ? {} : { "aria-hidden": true }),
    [isSidebarVisible]
  );

  return (
    <ul className={styles.sideBar} style={style} {...visibilityProps}>
      {sideBarItems}
    </ul>
  );
}

interface SideBarItemProps {
  item: NavItemInterface;
}

function SideBarItem({ item }: SideBarItemProps) {
  const { isSidebarVisible, setSidebarVisibility } = useContext(ViewContext);
  const { pathname } = useLocation();
  // const navigate = useNavigate();

  const hideSidebar = useCallback(() => {
    if (setSidebarVisibility) setSidebarVisibility(false);
  }, [setSidebarVisibility]);

  // controls tab index
  const visibilityProps = isSidebarVisible
    ? {}
    : { tabIndex: -1, style: { outline: "none" } };

  // controls className for active
  const classNameProps =
    item.path === pathname ? { className: styles.activeItem } : {};

  return (
    <li
      onTouchStart={() => {}}
      onClick={() => {
        // navigate(item.path);
        hideSidebar();
      }}
      {...classNameProps}
    >
      <Link to={item.path} onClick={() => hideSidebar()} {...visibilityProps}>
        <div className={styles.name}>{item.name}</div>
      </Link>
    </li>
  );
}
