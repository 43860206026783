import DummyLink from "../DummyLink";

import styles from "./Footer.module.css";

export default function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.footerBody}>
        FreebAlert {new Date().getFullYear()}. Emojis from{" "}
        <a
          href="https://emojitwo.github.io/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.footerLink}
        >
          EmojiTwo
        </a>{" "}
        <DummyLink onClick={showEmojiTwoAttribution}>(more info)</DummyLink> |{" "}
        <a
          href={process.env.PUBLIC_URL + "/privacy_policy.html"}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.footerLink}
        >
          Privacy
        </a>{" "}
      </div>
    </div>
  );
}

const showEmojiTwoAttribution = () =>
  alert(
    "Emoji artwork is provided by Emojitwo, originally released as Emojione 2.2 by Ranks.com with contributions from the Emojitwo community and is licensed under CC-BY 4.0.\n\nReference:\nhttps://emojitwo.github.io\nhttps://www.emojione.com\nhttps://creativecommons.org/licenses/by/4.0/legalcode"
  );
