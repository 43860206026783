import { memo } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useClipboard } from "use-clipboard-copy";

import styles from "./CopyToClipboardButton.module.css";

interface CopyToClipboardButtonProps {
  text: string;
  defaultContent: string;
  copiedContent: string;
}

function CopyToClipboardButton({
  text,
  defaultContent,
  copiedContent,
}: CopyToClipboardButtonProps) {
  const [isInCopiedState, setCopiedState] = useState(false);
  const clipboard = useClipboard();

  const handleCopyClick = useCallback(() => {
    if (!isInCopiedState) {
      clipboard.copy(); // copy to clipboard
      setCopiedState(true); // set state accordingly

      // after 1250ms, revert to non-copied state
      setTimeout(() => setCopiedState(false), 1250);
    }
  }, [clipboard, isInCopiedState]);

  return (
    <>
      <input type="text" value={text} ref={clipboard.target} hidden />
      <button className={styles.copyButton} onClick={handleCopyClick}>
        {isInCopiedState ? copiedContent : defaultContent}
      </button>
    </>
  );
}

export default memo(CopyToClipboardButton);
