import { memo, DetailedHTMLProps, AnchorHTMLAttributes } from "react";

function DummyLink(
  props: DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
) {
  return (
    <a
      {...props}
      href="#/"
      onClick={(e) => {
        e.preventDefault();
        props.onClick && props.onClick(e);
      }}
    >
      {props.children}
    </a>
  );
}

export default memo(DummyLink);
