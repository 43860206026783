import { Link } from "react-router-dom";
// import NativeShoppingAd from "../components/amazon/NativeShoppingAd";
import styles from "./Home.module.css";

export default function Home() {
  return (
    <div className="paddedContainer">
      <h1 className={styles.header}>Welcome to FreebAlert!</h1>
      <p className={styles.subtitle}>
        FreebAlert scours the internet for freebies and other goodies.
      </p>

      <a
        className={styles.homeButton}
        href="https://discord.gg/pED76hzDRH"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/discord_logo.png"
          className={styles.discordLogo}
          onDragStart={(e) => e.preventDefault()}
          alt="Discord Logo"
        />
        Join Our Discord
      </a>
      <Link className={styles.homeButton} to="/donate">
        Support Us
      </Link>

      {/* <NativeShoppingAd advertisementId="cb5dc13e-3d79-4901-ab34-8c95a68552a9" /> */}
    </div>
  );
}
